<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("address.address") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input m-2">
            <div class="row mb-3">
              <div class="col-5">
                <label class="form-label bold-12 text-gray">{{
                  $t("invoiceData.taxCode")
                }}</label>
                <input type="text" v-model="value.tax" class="form-control" />
              </div>
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("addNewContactsModal.name2")
                }}</label>
                <input type="text" v-model="value.name" class="form-control" />
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("address.country")
              }}</label>
              <select v-model="value.country" class="form-control">
                <option v-for="(c, i) in countries" :key="i" :value="i">
                  {{ c }}
                </option>
              </select>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("companies.postCode")
                }}</label>
                <input
                  type="text"
                  v-model="value.postCode"
                  class="form-control"
                />
              </div>
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.city")
                }}</label>
                <input type="text" v-model="value.city" class="form-control" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.street")
                }}</label>
                <input
                  type="text"
                  v-model="value.street"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label class="form-label bold-12 text-gray">{{
                  $t("address.house")
                }}</label>
                <input type="text" v-model="value.house" class="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "InvoiceData",
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ["update:modelValue", "update:show"],
  data: function () {
    return {
      value: {
        tax: this.modelValue?.tax,
        name: this.modelValue?.tax,
        country: this.modelValue?.country,
        city: this.modelValue?.city,
        street: this.modelValue?.street,
        house: this.modelValue?.house,
        postCode: this.modelValue?.postCode,
      },
      countries: {
        HU: "Hungary",
        DE: "Germany",
        SK: "Slovakia",
        RO: "Romania",
        AE: "United Arab Emirates",
      },
    };
  },
  mounted() {
    let myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }
  },
  computed: {
    all() {
      return (
        this.value?.country +
        this.value?.city +
        this.value?.street +
        this.value?.house +
        this.value?.postCode
      );
    },
  },
  watch: {
    all() {
      this.$emit("update:modelValue", {
        country: this.value?.country,
        city: this.value?.city,
        street: this.value?.street,
        house: this.value?.house,
        postCode: this.value?.postCode,
      });
    },
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    },
  },
  methods: {},
  components: {},
};
</script>
